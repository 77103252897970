<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="assignedlist">
        <div class="box">
            <p class="box2_tit">
                <span @click="dianji_fanhui">客户管理</span>
                <i class="el-icon-arrow-right"></i>
                <span>已分配</span>
            </p>
            <div class="box2_title">
                <div class="box2_title_box">
                    <ul>
                        <li v-for="(i,index) in biaotou1_list" :class="index==kan_shei?'zise':''" :key="index" @click="dianji_fenpei(i,index)">{{i}}</li>
                    </ul>
                    <div>
                        <!-- <div class="shenpi_xuanze_rqi">
                            <el-select v-model="fenpei_sj_zhi" clearable @change="shijian_xuanze" placeholder="请选择">
                                <el-option
                                v-for="item in fenpei_sj_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="min_rqi_qujian">
                            <el-date-picker
                                v-model="fenpei_time"
                                type="daterange"
                                class="rqi_qujian_kuang"
                                range-separator="至"
                                start-placeholder="开始日期"
                                width= '2.56rem'
                                end-placeholder="结束日期"
                                @change="rqi_xuanze"
                                >
                            </el-date-picker>
                        </div> -->
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" v-model="sousuo_text" @keyup.enter="sousuo">
                        <i @click="sousuo_text='',sousuo()" class="el-icon-circle-close"></i>
                    </div>
                </div>
            </div>
            <div class="xiaoshou_table">
                <el-table
                    ref="multipleTable"
                    :data="tableData"
                    :cell-style="liebiao"
                    :header-cell-style="biaotou"
                    tooltip-effect="dark"
                    height="6.5rem"
                    @row-click='hangdian'
                    style="width: 100%;font-size: 14px"
                    @selection-change="xuanzhong">
                    <el-table-column
                        type="selection"
                        width="77">
                    </el-table-column>
                    <el-table-column v-for="(i,index) in biao1_tit" :key="index"
                        :prop="i.con"
                        :label="i.name"
                        min-width="150"
                        >
                        <template slot-scope="props">{{props.row[i.con]}}
                            <span v-if="props.row[i.con]==undefined||props.row[i.con].length==0">—</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                @size-change="dianji_tiaoshu"
                @current-change="dianji_yeshu"
                :current-page="dangqian_yeshu"
                class="fenye_qi"
                :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
                :page-size='tiaoshu'
                layout="total,sizes, prev, pager, next, jumper"
                :total="dangqian_zongshu">
            </el-pagination>
        </div>
        <div class="beijing" v-if="xianshi_shezhi" @click="xianshi_shezhi=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">显示设置</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu"><img :src="xianshi_quanbu?require('../../assets/okblue.png'):require('../../assets/noccc.png')">全部</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for="(i,index) in zhanshi_list" :key="index" @click="xuanze_danxuan(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.name}}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">显示项：</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for='(i,index) in xianshi_xiang_list' :key="index">
                                {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu_xianshi(i,index)" src="../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_lie_queding">确定</span>
                    <span @click="xianshi_shezhi=false">取消</span>
                </p>
            </div>
        </div>
        <div class="beijing" v-if="shifou" @click="shifou=false">
            <div class="beijing_box2" @click.stop>
                <!-- <p class="box_con">您当前流程暂未编辑，请编辑<router-link to='/appsett'>审批设置</router-link></p> -->
                <p class="box_con"><span>{{danzi_name}}</span>尚未设置审批流程，请联系管理员进行流程设置。</p>
                <p class="foot">
                    <!-- <span @click="shifou=false">取消</span> -->
                    <span @click="shifou=false">我知道了</span>
                    <!-- <router-link to='/appsett'>确定</router-link> -->
                </p>
            </div>
        </div>
        <div class="beijing" v-if="xitong_fenpei_zhanshi" @click="xitong_fenpei_zhanshi=false">
            <div class="beijing_box3" @click.stop>
                <!-- <p class="box_con">您当前流程暂未编辑，请编辑<router-link to='/appsett'>审批设置</router-link></p> -->
                <div class="xitong_fenpei_xuanze_bumen">
                    <span style="margin-right:0.1rem;">选择部门:</span>
                    <el-select v-model="dept_id" placeholder="请选择">
                        <el-option
                        v-for="item in dept_list"
                        :key="item.dept_id"
                        :label="item.dept_name"
                        :value="item.dept_id">
                        </el-option>
                    </el-select>
                </div>
                <p class="foot">
                    <span @click="xitong_fenpei">确定</span>
                    <span @click="xitong_fenpei_zhanshi=false">取消</span>
                    <!-- <router-link to='/appsett'>确定</router-link> -->
                </p>
            </div>
        </div>
        <!-- tishi_fenpei_kehu -->
        <!--  v-if="tishi_fenpei_kehu" -->
        <div class="beijing" v-if="tishi_fenpei_kehu">
            <div class="beijing_box4" @click.stop>
                <i class="el-icon-error" @click="zhipai_dongzuo(2)"></i>
                <p class="beijing_box4_tit">{{tanchuang_neirong.allot_user_name}}向您指派客户</p>
                <ul>
                    <li v-for="(i,index) in tanchuang_neirong.list" :key="index">
                        <el-tooltip class="item" effect="dark" :content="i.cust_name+'--'+i.dept_name" placement="top">
                            <span>{{i.cust_name}}:</span>
                        </el-tooltip>
                        <span @click="i.zhi=inde" v-for="(it,inde) in jieshou_jujue_list" :key="inde">
                            <img :src="i.zhi==inde?require('../../assets/me/xuan_zi.png'):require('../../assets/me/weixuan_zi.png')" alt="">
                        {{it}}</span>
                    </li>
                </ul>
                <p class="foot">
                    <span @click="zhipai_dongzuo(1)">确定</span>
                    <span @click="zhipai_dongzuo(2)">取消</span>
                </p>
                <!-- <p class="box_con">您当前流程暂未编辑，请编辑<router-link to='/appsett'>审批设置</router-link></p> -->
                <!-- <div class="box_con">
                    <p>{{tanchuang_neirong.allot_user_name}} 在{{tanchuang_neirong.allot_time}}向您指派<span style="font-size:0.12rem;color:rgb(100, 149, 237);">{{tanchuang_neirong.cust_name}}</span></p>
                </div>
                <p class="foot">
                    <span @click="zhipai_dongzuo(1)">接受</span>
                    <span @click="zhipai_dongzuo(2)">拒绝</span>
                </p> -->
            </div>
        </div>
        <div class="beijing" @click='youce_xiangqing=false' v-if="youce_xiangqing">
            <div class="youce_box" @click.stop >
                <div class="yanjing yanjing_boxS">
                    <table>
                        <tr>
                            <td>成交时间</td>
                            <td>成交金额({{wenzi_jine_zhuanhua(right_zhanshi.chengjiao_jine)}})</td>
                            <td>退回次数</td>
                            <td>状态</td>
                        </tr>
                        <tr>
                            <td>{{right_zhanshi.chengjiao_sj}}</td>
                            <td>{{jine_zhuanhua(right_zhanshi.chengjiao_jine)}}</td>
                            <td>{{right_zhanshi.tuihui_cishu}}</td>
                            <td>{{right_zhanshi.zhuangtai}}</td>
                        </tr>
                    </table>
                    <img src="../../assets/me/eye.png" @click="dakai_danju(xiangxi_xinxi)" alt="">
                </div>
                <div class="youce_xiangxi_xinxi" v-for='(item,idx) in xiangxi_xinxi' :key="idx">
                    <!-- {{idx+1}}、 -->
                    <p class="xiangxi_tit" @click="xiangqing_zhankai=!xiangqing_zhankai">详细信息<i :class="xiangqing_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_zhankai" class="transition-box">
                            <!-- {{xiangxi_xinxi}} -->
                            <p v-if="item.cust_name!==undefined">客户名称：{{item.cust_name}}</p>
                            <p v-if="item.file_no!==undefined">归档号：{{item.file_no}}</p>
                            <p v-if='item.staff_name!==undefined'>负责人：{{item.staff_name}}</p>
                            <p v-if="item.dept_name!==undefined">部门：{{item.dept_name}}</p>
                            <div v-if="xiangqing.flow_id=='7TvJHPyovM'">
                                <ul>
                                    <li v-for='(i,index) in item.contract_detail' :key="index">
                                        <p>决策人({{index+1}})</p>
                                        <p v-if="i.name!==undefined">姓名：{{i.name}}</p>
                                        <p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
                                        <p v-if="i.dept!==undefined">部门：{{i.dept}}</p>
                                        <p v-if="i.post!==undefined">职务：{{i.post}}</p>
                                        <p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
                                        <p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
                                        <p v-if="i.birthday!==undefined">生日：{{i.birthday}}</p>
                                        <p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
                                        <p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
                                        <p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
                                        <p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
                                        <p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
                                    </li>
                                </ul>
                                <p v-if="item.province!==undefined">省份：{{item.province}}</p>
                                <p v-if="item.city!==undefined">城市：{{item.city}}</p>
                                <p v-if="item.industry!==undefined">行业：{{item.industry}}</p>
                                <p v-if="item.source!==undefined">客户来源：{{item.source}}</p>
                                <p v-if="item.level!==undefined">客户等级：{{item.level}}</p>
                                <p v-if="item.cat_name!==undefined">客户标签：{{item.cat_name}}</p>
                                <p v-if="item.biz_addr!==undefined">经营地址：{{item.biz_addr}}</p>
                                <p v-if="item.rel_p_com!==undefined">母公司名称：{{item.rel_p_com}}</p>
                                <p v-if="item.register_addr!==undefined">注册地址：{{item.register_addr}}</p>
                                <p v-if="item.legal_name!==undefined">法定代表人：{{item.legal_name}}</p>
                                <p v-if="item.uscc!==undefined">营业执照号：{{item.uscc}}</p>
                                <p v-if="item.register_capital!==undefined">注册资本：{{item.register_capital}}</p>
                                <p v-if="item.register_date!==undefined">成立日期：{{item.register_date}}</p>
                                <p v-if="item.biz_term!==undefined">营业期限：{{item.biz_term}}</p>
                                <p v-if="item.biz_scope!==undefined">营业范围：{{item.biz_scope}}</p>
                                <p v-if="item.biz_lic_pic!==undefined">营业执照：<img @click="tupian_dianji(item.biz_lic_pic)" class="tupian_kuang" :src="item.biz_lic_pic" alt=""></p>
                            </div>
                            <div v-if="xiangqing.flow_id=='Z7VDOK9HGu'">
                                <p v-if="item.visit_type!==undefined">拜访方式：{{item.visit_type}}</p>
                                <p v-if="item.visit_time!==undefined">拜访时间：{{item.visit_time}}</p>
                                <p v-if="item.contact!==undefined">决策人：{{item.contact}}</p>
                                <p v-if="item.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(item.current_stage)}}</p>
                                <p v-if="item.next_stage!==undefined">预计下一漏斗阶段：{{jieduan_hanzi(item.next_stage)}}</p>
                                <p v-if="item.remarks!==undefined">备注：{{item.remarks}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='453y6pLD52'">
                                <p v-if="item.start_time!==undefined">开始时间：{{item.start_time}}</p>
                                <p v-if="item.end_time!==undefined">结束时间：{{item.end_time}}</p>
                                <p v-if="item.hour_long!==undefined">时长：{{item.hour_long}}小时</p>
                                <p v-if="item.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(item.current_stage)}}</p>
                                <p v-if="item.funn_stage_change_flag!==undefined">漏斗阶段变化：{{item.funn_stage_change_flag}}</p>
                                <p v-if="item.funn_stage!==undefined">漏斗达成阶段：{{jieduan_hanzi(item.funn_stage)}}</p>
                                <p v-if="item.change_cause!==undefined">阶段变化原因：{{item.change_cause}}</p>
                                <p v-if="item.remarks!==undefined">备注：{{item.remarks}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='PLOQ9ku3Ip'">
                                <ul>
                                    <li v-for="(i,index) in item.prod_detail" :key="index">
                                        <p>产品明细({{index+1}})</p>
                                        <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                        <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                        <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                        <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                        <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                        <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                        <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                        <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                        <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                        <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                        <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.stage_funn!==undefined">漏斗阶段：{{jieduan_hanzi(item.stage_funn)}}</p>
                                <p v-if="item.amt_funn!==undefined">漏斗金额：{{item.amt_funn}}</p>
                                <p v-if="item.date_con_est!==undefined">预计合同日期：{{item.date_con_est}}</p>
                                <p v-if="item.rate_funn_disc!==undefined">漏斗折算比例：{{item.rate_funn_disc}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='Eb3n7CKQzi'">
                                <p v-if="item.help_order_flag!==undefined">是否为助单行为：{{item.help_order_flag}}</p>
                                <p v-if="item.disp_staff_name!==undefined">派工人员：{{item.disp_staff_name}}</p>
                                <p v-if="item.help_order_type!==undefined">助单类型：{{item.help_order_type}}</p>
                                <p v-if="item.biz_trip_type!==undefined">出差类型：{{item.biz_trip_type}}</p>
                                <p v-if="item.addr!==undefined">地址：{{item.addr}}</p>
                                <p v-if="item.contact!==undefined">决策人：{{item.contact}}</p>
                                <p v-if="item.mobile!==undefined">手机：{{item.mobile}}</p>
                                <p v-if="item.trip_start_time!==undefined">开始时间：{{item.trip_start_time}}</p>
                                <p v-if="item.trip_end_time!==undefined">结束时间：{{item.trip_end_time}}</p>
                                <p v-if="item.hour_long!==undefined">时长：{{item.hour_long}}小时</p>
                                <ul>
                                    <li v-for="(i,index) in item.prod_detail" :key="index">
                                        <p>产品明细({{index+1}})</p>
                                        <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                        <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                        <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                        <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                        <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                        <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                        <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                        <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                        <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                        <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                        <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                        <p v-if="i.disp_item!==undefined">派工项目：{{i.disp_item}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.remarks!==undefined">备注：{{item.remarks}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='qIwxmsMTgv'">
                                <p v-if="item.date_con!==undefined">合同日期：{{item.date_con}}</p>
                                <p v-if="item.belong_to_organ!==undefined">所属公司：{{item.belong_to_organ}}</p>
                                <p v-if="item.industry!==undefined">行业：{{item.industry}}</p>
                                <p v-if="item.con_type!==undefined">合同类型：{{item.con_type}}</p>
                                <p v-if="item.con_valid_term!==undefined">合同有效时间：{{item.con_valid_term}}</p>
                                <ul>
                                    <li v-for="(i,index) in item.prod_list" :key="index">
                                        <p>产品明细({{index+1}})</p>
                                        <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                        <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                        <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                        <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                        <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                        <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                        <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                        <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                        <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                        <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                        <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.amt_con!==undefined">合同金额：{{item.amt_con}}</p>
                                <ul>
                                    <li v-for="(i,index) in item.amt_list" :key="index">
                                        <p>开票({{index+1}})</p>
                                        <p v-if="i.date_inv_est!==undefined">预计发票日期：{{i.date_inv_est}}</p>
                                        <p v-if="i.amt_inv_est!==undefined">预计发票金额：{{i.amt_inv_est}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.amt_con!==undefined">预计发票金额合计：{{item.amt_con}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='VMCizuVOXb'">
                                <p v-if="item.belong_to_organ!==undefined">所属公司：{{item.belong_to_organ}}</p>
                                <p v-if="item.date_inv!==undefined">发票日期：{{item.date_inv}}</p>
                                <p v-if="item.inv_type!==undefined">发票类型：{{item.inv_type}}</p>
                                <ul>
                                    <li v-for="(i,index) in item.prod_list" :key="index">
                                        <p>发票内容({{index+1}})</p>
                                        <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                        <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                        <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                        <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                        <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                        <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                        <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                        <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                        <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                        <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                        <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                        <p v-if="i.price_unit_no_tax!==undefined">不含税折后单价：{{i.price_unit_no_tax}}</p>
                                        <p v-if="i.amt_no_tax!==undefined">不含税金额：{{i.amt_no_tax}}</p>
                                        <p v-if="i.rate_tax!==undefined">税率：{{i.rate_tax}}%</p>
                                        <p v-if="i.amt_tax!==undefined">税额：{{i.amt_tax}}</p>
                                        <p v-if="i.amt_inv_detail!==undefined">发票金额：{{i.amt_inv_detail}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.amt_no_tax_sum!==undefined">合计不含税金额：{{item.amt_no_tax_sum}}</p>
                                <p v-if="item.amt_tax_sum!==undefined">合计税额：{{item.amt_tax_sum}}</p>
                                <p v-if="item.amt_inv!==undefined">合计发票金额：{{item.amt_inv}}</p>
                                <p v-if="item.amt_inv_sum!==undefined">累计发票金额：{{item.amt_inv_sum}}</p>
                                <p v-if="item.amt_inv_un!==undefined">合同未开发票金额：{{item.amt_inv_un}}</p>
                                <ul>
                                    <li v-for="(i,index) in item.amt_list" :key="index">
                                        <p>回款({{index+1}})</p>
                                        <p v-if="i.date_rec_est!==undefined">预计回款日期：{{i.date_rec_est}}</p>
                                        <p v-if="i.amt_rec_est!==undefined">预计回款金额：{{i.amt_rec_est}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.cust_name!==undefined">客户：{{item.cust_name}}</p>
                                <p v-if="item.tax_no!==undefined">纳税识别号：{{item.tax_no}}</p>
                                <p v-if="item.mobile!==undefined">电话：{{item.mobile}}</p>
                                <p v-if="item.addr!==undefined">地址：{{item.addr}}</p>
                                <p v-if="item.bank!==undefined">开户行：{{item.bank}}</p>
                                <p v-if="item.bank_acct!==undefined">开户行账号：{{item.bank_acct}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='He5ln0bdDO'">
                                <p v-if="item.date_rec!==undefined">回款日期：{{item.date_rec}}</p>
                                <p v-if="item.inv_code!==undefined">发票代码：{{item.inv_code}}</p>
                                <p v-if="item.inv_no!==undefined">发票号码：{{item.inv_no}}</p>
                                <ul>
                                    <li v-for="(i,index) in item.prod_detail" :key="index">
                                        <p>产品回款({{index+1}})</p>
                                        <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                        <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                        <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                        <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                        <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                        <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                        <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                        <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                        <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                        <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                        <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.amt_rec!==undefined">回款金额：{{item.amt_rec}}</p>
                                <p v-if="item.amt_rec_sum!==undefined">累计回款金额：{{item.amt_rec_sum}}</p>
                                <p v-if="item.amt_inv_sum!==undefined">累计开票金额：{{item.amt_inv_sum}}</p>
                                <p v-if="item.amt_rec_unrec!==undefined">应收金额：{{item.amt_rec_unrec}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='cvRyWGiUQH'">
                                <p v-if="item.start_time!==undefined">开始时间：{{item.start_time}}</p>
                                <p v-if="item.end_time!==undefined">结束时间：{{item.end_time}}</p>
                                <p v-if="item.hour_long!==undefined">时长：{{item.hour_long}}小时</p>
                                <p v-if="item.go_out_cause!==undefined">外出事由：{{item.go_out_cause}}</p>
                                <p v-if="item.year_hour_long!==undefined">年累计外出时长：{{item.year_hour_long}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='GBh7N2jMsi'">
                                <p v-if="item.remarks!==undefined">出差备注：{{item.remarks}}</p>
                                <ul>
                                    <li v-for="(i,index) in item.trip_detail" :key="index">
                                        <p>行程({{index+1}})</p>
                                        <p v-if="i.transport!==undefined">交通工具：{{i.transport}}</p>
                                        <p v-if="i.leave_city!==undefined">出发城市：{{i.leave_city}}</p>
                                        <p v-if="i.arrive_city!==undefined">目的城市：{{i.arrive_city}}</p>
										<p v-if="i.start_time!==undefined">开始时间：{{i.start_time}}{{i.start_noon==1?'下午':'上午'}}</p>
										<p v-if="i.end_time!==undefined">结束时间：{{i.end_time}}{{i.end_noon==1?'下午':'上午'}}</p>
                                        <p v-if="i.hour_long!==undefined">时长（天）：{{i.hour_long}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.trip_days!==undefined">出差天数：{{item.trip_days}}</p>
                                <p v-if="item.year_trip_days!==undefined">年累计出差天数：{{item.year_trip_days}}</p>
                            </div>
                            <div v-if="xiangqing.flow_id=='H49d4edc3T'">
                                <p v-if="item.date_deliver!==undefined">日期：{{item.date_deliver}}</p>
                                <ul>
                                    <li v-for="(i,index) in item.prod_detail" :key="index">
                                        <p>产品明细({{index+1}})</p>
                                        <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                        <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                        <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                        <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                        <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                        <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                        <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                        <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                        <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                        <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                    </li>
                                </ul>
                                <p v-if="item.amt_deliver!==undefined">交付金额：{{item.amt_deliver}}</p>
                            </div>
                            <ul>
								<li v-for="(i,index) in item.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
										<p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{it.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
										<p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{it.flow_desc}}:{{it.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(it,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in it.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'&&ite.img_url!=undefined&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </transition>
                </div>
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxi">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_zhankai=!xiangqing_kehu_zhankai">客户操作日志<i :class="xiangqing_kehu_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_zhankai" class="transition-box">
                            <ul>
                                <li v-for="(i,index) in zhanshi_lishi" :key="index">
                                    <p v-if="i.type=='1'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统分配给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='2'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.dept_name}}{{i.staff_name}}退回公海池；</p>
                                    <p v-if="i.type=='3'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}从{{i.dept_name}}冷冻仓激活到公海池；</p>
                                    <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}接收{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}拒绝{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='6'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入公海池；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='8'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入已分配；</p>
                                    <p v-if="i.type=='9'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}修改；</p>
                                    <p v-if="i.type=='10'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入</p>
                                    <p v-if="i.type=='11'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动退回</p>
                                    <p v-if="i.type=='12'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动从公海池退回冷冻仓</p>
                                    <!-- <p v-if="i.type=='1'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被分配给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='2'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.dept_name}}{{i.operate_user_name}}退回公海池；</p>
                                    <p v-if="i.type=='3'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}从冷冻仓激活；</p>
                                    <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，{{i.accept_time}}{{i.staff_name}}已接收；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，{{i.accept_time}}{{i.staff_name}}已拒绝；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，待{{i.staff_name}}接受；</p>
                                    <p v-if="i.type=='6'&&i.operate_user_id=='sys'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入公海池；</p>
                                    <p v-if="i.type=='6'&&i.operate_user_id!='sys'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}{{i.dept_name}}{{i.operate_user_name}}被系统录入公海池；</p> -->
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
                <!-- 附件下载多了个a注意，以后该这块需要注意 -->
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxia">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_fujian=!xiangqing_kehu_fujian">附件下载<i :class="xiangqing_kehu_fujian?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_fujian" class="transition-box">
                            <p class="transition-box_tit">
                                选择归档号：
                            </p>
                            <p class='transition-box_tit_file'>
                                <span v-for="(i,index) in fujian_list" @click="dianji_guidang_hao(index)" :key="index"><img :src="zhanshi_guidang_hao==index?require('../../assets/me/xuan_zi.png'):require('../../assets/me/weixuan_zi.png')" alt="">{{i.file_no}}</span>
                            </p>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].con_annex!=undefined">
                                <p class="fujian_con_tit">合同附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].con_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].inv_annex!=undefined">
                                <p class="fujian_con_tit">发票附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].inv_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].rec_annex!=undefined">
                                <p class="fujian_con_tit">回款附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].rec_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined">
                                <p class="fujian_con_tit">交付附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].deliver_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <p class="fujian_foot" v-if="fujian_list.length!=0&&(fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined||fujian_list[zhanshi_guidang_hao].con_annex!=undefined||fujian_list[zhanshi_guidang_hao].rec_annex!=undefined||fujian_list[zhanshi_guidang_hao].inv_annex!=undefined)">
                                <span @click="dianji_quanxuan_fujian">
                                    <img :src="fujian_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    全选
                                </span>
                                <span @click="dianji_piliang_xiazi">批量下载</span>
                            </p>
                            <p class="no_fujian" v-if="fujian_list.length==0||(fujian_list[zhanshi_guidang_hao].deliver_annex==undefined&&fujian_list[zhanshi_guidang_hao].con_annex==undefined&&fujian_list[zhanshi_guidang_hao].rec_annex==undefined&&fujian_list[zhanshi_guidang_hao].inv_annex==undefined)">该归档号下没有可以下载的附件</p>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="beijing" v-if="tuihui_bumen">
            <div class="beijing_box3" @click.stop>
                <div class="xitong_fenpei_xuanze_bumen">
                    <span style="margin-right:0.1rem;">选择部门:</span>
                    <el-select v-model="tuihui_dept_id" placeholder="请选择">
                        <el-option
                        v-for="item in tuihui_dept_list"
                        :key="item.dept_id"
                        :label="item.dept_name"
                        :value="item.dept_id">
                        </el-option>
                    </el-select>
                </div>
                <p class="foot">
                    <span @click="tuihui_queding">确定</span>
                    <span @click="tuihui_bumen=false">取消</span>
                    <!-- <router-link to='/appsett'>确定</router-link> -->
                </p>
            </div>
        </div>
		<yanjing ref="eye"></yanjing>
		<tupian ref="bigphote"></tupian>
    </div>
</template>

<script>
import { query_all_module_sale_userid, query_form_replace_write, query_customer_else_info, query_cust_allot_log_list, query_all_module_read_power_list, query_customer_allot_rule_detail, query_customer_mng_log_list, query_customer_detail, update_customer_allot_log, query_customer_allot_log_list, system_allot_customer, query_staff_dept_list, query_user_info, back_customer, query_flow_info_all, query_flow_form_have_set, query_customer_list_alloted, query_form_use_power_list, customer_annex_download } from '../../api/api.js'
import BiaodanXiangqing from '../../components/biaodan/biaodanxq'
import yanjing from '../../page/pagea/yanjing'
import tupian from '../../components/bigphote/bigphote'
export default {
  name: 'assignedlist',
  data () {
    return {
      biaotou1_list: [],
      xianshi_shezhi: false,
      fenpei_sj_zhi: '',
      fenpei_sj_list: [
        {
          label: '成交时间',
          value: '2'
        },
        {
          label: '分配时间',
          value: '1'
        }
      ],
      fenpei_time: '', // 已分配里的时间区间选择
      tableData: [],
      xuan_zhong: [],
      biao1_tit: [
        {
          name: '客户',
          con: 'cust_name'
        },
        {
          name: '客户等级',
          con: 'level'
        },
        {
          name: '客户标签',
          con: 'tag_name'
        },
        {
          name: '负责人',
          con: 'staff_name'
        },
        {
          name: '部门',
          con: 'dept_name'
        },
        {
          name: '决策人',
          con: 'rel_name'
        },
        {
          name: '客户来源',
          con: 'source'
        },
        {
          name: '行业',
          con: 'industry'
        },
        {
          name: '省份',
          con: 'province'
        }
      ],
      // {    这段说相应慢   无奈  注掉呗
      //         name:'成交时间',
      //         con:'date_trans'
      //     },
      //     {
      //         name:'退回次数',
      //         con:'back_num'
      //     },
      //     {
      //         name:'成交金额(元)',
      //         con:'chengjiao_jine'
      //     },
      //     {
      //         name:'状态',
      //         con:'zhuangtai'
      //     },
      xianshi_xiang_list: [],
      zhanshi_list: [
        {
          name: '客户',
          con: 'cust_name',
          zhi: false
        },
        {
          name: '归档号',
          con: 'file_no',
          zhi: false
        },
        {
          name: '负责人',
          con: 'staff_name',
          zhi: false
        },
        {
          name: '部门',
          con: 'dept_name',
          zhi: false
        },
        {
          name: '省份',
          con: 'province',
          zhi: false
        },
        {
          name: '城市',
          con: 'city',
          zhi: false
        },
        {
          name: '决策人',
          con: 'rel_name',
          zhi: false
        },
        {
          name: '行业',
          con: 'industry',
          zhi: false
        },
        {
          name: '客户来源',
          con: 'source',
          zhi: false
        },
        {
          name: '客户等级',
          con: 'level',
          zhi: false
        },
        {
          name: '客户标签',
          con: 'tag_name',
          zhi: false
        },
        {
          name: '经营地址',
          con: 'biz_addr',
          zhi: false
        },
        {
          name: '母公司名称',
          con: 'rel_p_com',
          zhi: false
        },
        {
          name: '注册地址',
          con: 'register_addr',
          zhi: false
        },
        {
          name: '法定代表人',
          con: 'legal_name',
          zhi: false
        },
        {
          name: '营业执照号',
          con: 'uscc',
          zhi: false
        },
        {
          name: '注册资本',
          con: 'register_capital',
          zhi: false
        },
        {
          name: '成立日期',
          con: 'register_date',
          zhi: false
        },
        {
          name: '营业期限',
          con: 'biz_term',
          zhi: false
        },
        {
          name: '营业范围',
          con: 'biz_scope',
          zhi: false
        }
      ],
      xianshi_quanbu: false,
      sousuo_text: '',
      danzi_name: '',
      shifou: false,
      xitong_fenpei_zhanshi: false,
      dept_list: [],
      dept_id: '',
      tishi_fenpei_kehu: false,
      tanchuang_neirong: {},
      youce_xiangqing: false,
      xiangxi_xinxi: [],
      xiangqing_zhankai: false,
      xiangqing: {
        flow_id: '7TvJHPyovM'
      },
      xiangqing_kehu_zhankai: false,
      zhanshi_lishi: [],
      shifou_keshi: false,
      kan_shei: '-1',
      quanbu_kejian: false,
      dangqian_yeshu: sessionStorage.getItem('cbr_yifen_pei') != undefined && sessionStorage.getItem('cbr_yifen_pei').length != 0 ? sessionStorage.getItem('cbr_yifen_pei') : 1,
      dangqian_zongshu: 0,
      zhijie_quanbu: false,
      xiangqing_kehu_fujian: false,
      fujian_list: [],
      zhanshi_guidang_hao: '0',
      fujian_quanxuan: false,
      tuihui_bumen: false,
      tuihui_dept_id: '',
      tuihui_dept_list: [],
      // 2020/6/4补充
      jieshou_jujue_list: ['接受', '拒绝'],
      xunhuan_xiabiao: '0', // 点取消进行加一,
      tiaoshu: 10, // 当前页面条数
      right_zhanshi: {
        chengjiao_sj: '',
        chengjiao_jine: '',
        tuihui_cishu: '',
        zhuangtai: ''
      },

      jichuxinxi: {},
      shifou_have_daixie: false // 用来判断是否有代写权限
    }
  },
  mounted () {
  },
  components: { BiaodanXiangqing, yanjing, tupian },
  created () {
    sessionStorage.removeItem('mlbb_one_shezhi')
    sessionStorage.removeItem('mlbb_four_shezhi')
    sessionStorage.removeItem('mlbb_shenpi_zhengyi')
    sessionStorage.removeItem('mlbb_biaodan_sheji')
    sessionStorage.removeItem('mlbb_liucheng_shezhi')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_list')

    sessionStorage.removeItem('mlbb_shenpi_biaodan')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_2')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_3')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_4')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_5')
    this.chushi_chaxun()
    this.jichu()
  },
  watch: {
    xianshi_xiang_list: {
      handler (newValue, oldValue) {
        if (this.xianshi_xiang_list.length == this.zhanshi_list.length) {
          this.xianshi_quanbu = true
        } else {
          this.xianshi_quanbu = false
        }
      },
      deep: true
    },
    xianshi_shezhi: {
      handler (newValue, oldValue) {
        if (this.xianshi_shezhi == false) {
          this.zhanshi_list.forEach(item => { item.zhi = false })
          this.xianshi_xiang_list = []
        }
      }
    },
    dangqian_yeshu () {
      sessionStorage.setItem('cbr_yifen_pei', JSON.stringify(this.dangqian_yeshu))
      this.liebiao_jiekou()
    },
    tiaoshu () {
      this.liebiao_jiekou()
    },
    fujian_list: {
      handler (newValue, oldValue) {
        this.panduan_fujian_quanxuan()
      },
      deep: true
    },
    tuihui_bumen () {
      if (!this.tuihui_bumen) {
        this.tuihui_dept_id = '',
        this.tuihui_dept_list = []
      }
    },
    youce_xiangqing () {
      if (!this.youce_xiangqing) {
        this.xiangqing_kehu_fujian = false
        this.zhanshi_lishi = []
        this.xiangqing_kehu_zhankai = false
        this.right_zhanshi = {
          chengjiao_sj: '',
          chengjiao_jine: '',
          tuihui_cishu: '',
          zhuangtai: ''
        }
      }
    }
  },
  methods: {
    chushi_chaxun () { // 查询他有没有代写权限
      query_form_replace_write({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          const _this = this
          console.log(date)
          if (date != undefined && date != null && date.length != 0) {
            date.forEach(item => {
              if (item.staff_id == _this.$jichuxinxi().user_id) {
                _this.shifou_have_daixie = true
              }
            })
          } else {
            this.shifou_have_daixie = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    jichu () {
      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.quanbu_kejian = true
          } else {
            this.quanbu_kejian = false
            this.huoqu_all_mokuai_xiaoshou()
          }
        } else if (res.data.code == 500) {}
      })
      query_customer_allot_rule_detail({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body == null) {
            this.shifou_keshi = false
            this.$message({
              message: '还未分配规则',
              type: 'warning'
            })
          } else {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date.flag_visible == 1) { // 1可视，0不可视
              this.shifou_keshi = true
            } else {
              this.shifou_keshi = false
            }
          }
        } else if (res.data.code == 500) {} else if (res.data.code == 10300) {
          this.$message({
            message: '企业还没有设置分配规则',
            type: 'warning'
          })
        }
      })
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          query_form_use_power_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id
            }
          }).then(ras => {
            console.log(ras)
            if (ras.data.code == 200) {
              const data = JSON.parse(ras.data.body.data)
              console.log(data)
              const zhan = []
              data.forEach(item => {
                // if(item.flow_id=='7TvJHPyovM'){
                //     zhan.push('1111')
                // }
                item.children.forEach(it => {
                  if (it.flow_id == '7TvJHPyovM') {
                    zhan.push('1111')
                  }
                })
              })
              if (zhan.length != 0) {
                // aim_flag
                if (res.data.body.aim_flag == 1) {
                  if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
                    if (this.shifou_keshi) {
                      this.biaotou1_list = ['新增', '编辑', '退回', '显示设置', '看我', '看员工', '看全部']
                    } else {
                      this.biaotou1_list = ['系统分配', '新增', '编辑', '退回', '显示设置', '看我', '看员工', '看全部']
                    }
                  } else {
                    if (this.quanbu_kejian) {
                      if (this.shifou_keshi) {
                        this.biaotou1_list = ['新增', '编辑', '退回', '显示设置', '看我', '看员工', '看全部']
                      } else {
                        this.biaotou1_list = ['系统分配', '新增', '编辑', '退回', '显示设置', '看我', '看员工', '看全部']
                      }
                    } else {
                      if (this.shifou_keshi) {
                        this.biaotou1_list = ['新增', '编辑', '退回', '显示设置']
                      } else {
                        this.biaotou1_list = ['系统分配', '新增', '编辑', '退回', '显示设置']
                      }
                    }
                  }
                } else {
                  if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
                    if (this.shifou_keshi) {
                      this.biaotou1_list = ['新增', '编辑', '退回', '显示设置', '看我', '看员工', '看全部']
                    } else {
                      this.biaotou1_list = ['系统分配', '新增', '编辑', '退回', '显示设置', '看我', '看员工', '看全部']
                    }
                  } else {
                    if (this.quanbu_kejian) {
                      if (this.shifou_keshi) {
                        this.biaotou1_list = ['新增', '编辑', '退回', '显示设置', '看我', '看员工', '看全部']
                      } else {
                        this.biaotou1_list = ['系统分配', '新增', '编辑', '退回', '显示设置', '看我', '看员工', '看全部']
                      }
                    } else {
                      if (this.shifou_keshi) {
                        this.biaotou1_list = ['新增', '编辑', '退回', '显示设置']
                      } else {
                        this.biaotou1_list = ['系统分配', '新增', '编辑', '退回', '显示设置']
                      }
                    }
                  }
                }
              } else {
                if (res.data.body.aim_flag == 1) {
                  if (res.data.body.dept_manager == 1 && res.data.body.staff_level != 1 && res.data.body.staff_level != 2) {
                    if (this.shifou_keshi) {
                      this.biaotou1_list = ['显示设置', '看我', '看员工', '看全部']
                    } else {
                      this.biaotou1_list = ['系统分配', '退回', '显示设置', '看我', '看员工', '看全部']
                    }
                  } else if (res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
                    if (this.shifou_keshi) {
                      this.biaotou1_list = ['显示设置', '看我', '看员工', '看全部']
                    } else {
                      this.biaotou1_list = ['系统分配', '退回', '显示设置', '看我', '看员工', '看全部']
                    }
                  } else {
                    if (this.quanbu_kejian) {
                      this.zhijie_quanbu = true
                      this.biaotou1_list = ['显示设置']
                    } else {
                      this.zhijie_quanbu = false
                      if (this.shifou_keshi) {
                        this.biaotou1_list = ['显示设置']
                      } else {
                        this.biaotou1_list = ['显示设置']
                      }
                    }
                  }
                } else {
                  if (res.data.body.dept_manager == 1 && res.data.body.staff_level != 1 && res.data.body.staff_level != 2) {
                    if (this.shifou_keshi) {
                      this.biaotou1_list = ['显示设置', '看我', '看员工', '看全部']
                    } else {
                      this.biaotou1_list = ['显示设置', '看我', '看员工', '看全部']
                    }
                  } else if (res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
                    if (this.shifou_keshi) {
                      this.biaotou1_list = ['显示设置', '看我', '看员工', '看全部']
                    } else {
                      this.biaotou1_list = ['显示设置', '看我', '看员工', '看全部']
                    }
                  } else {
                    if (this.quanbu_kejian) {
                      this.biaotou1_list = ['显示设置']
                      this.zhijie_quanbu = true
                    } else {
                      this.zhijie_quanbu = false
                      if (this.shifou_keshi) {
                        this.biaotou1_list = ['显示设置']
                      } else {
                        this.biaotou1_list = ['显示设置']
                      }
                    }
                  }
                }
              }
              if (this.kan_shei == '-1') {
                for (let a = 0; a < this.biaotou1_list.length; a++) {
                  if (this.biaotou1_list[a] == '看员工') {
                    this.kan_shei = a
                  }
                }
              }
              this.liebiao_jiekou()
            } else if (ras.data.code == 500) {}
          })
        } else if (res.data.code == 500) {}
      })
      console.log(this.biaotou1_list[this.biaotou1_list.length - 1])
      this.zhipai_jiekou()
    },
    // 获取是否有销售权限
    huoqu_all_mokuai_xiaoshou () {
      query_all_module_sale_userid({
        data: {
          ent_id: this.$ent_id(),
          read_flag: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          if (date != null) {
            console.log(date)
            let ids = []
            if (date.staff_ids != undefined) {
              ids = date.staff_ids.split(',')
            }
            console.log(ids)

            ids.forEach(item => {
              if (item == this.$jichuxinxi().user_id) {
                this.quanbu_kejian = true
              }
            })
          }
        } else if (res.data.code == 500) {}
      })
    },
    liebiao_jiekou () { // 已分配列表接口
      // this.fenpei_sj_zhi=this.fenpei_sj_zhi.length==0?'2':this.fenpei_sj_zhi
      // let date=this.fenpei_time
      // let start_time=null
      // let end_time=null
      // if(date!=null&&date!=undefined&&date.length!=0){
      //     start_time=date[0].getFullYear() + "-" + this.$func.pa(date[0].getMonth() + 1) + "-"+this.$func.pa(date[0].getDate())
      //     end_time=date[1].getFullYear() + "-" + this.$func.pa(date[1].getMonth() + 1) + "-"+this.$func.pa(date[1].getDate())
      // }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null,
        // date_start:start_time,
        // date_end:end_time,
        look_type: this.kan_shei == '-1' ? '2' : (this.biaotou1_list[this.kan_shei] == '看员工' ? '1' : (this.biaotou1_list[this.kan_shei] == '看我' ? '2' : '3')),
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + ''
      }
      query_customer_list_alloted({ data: obj }).then(rzs => {
        console.log(rzs)
        if (rzs.data.code == 200) {
          const date = JSON.parse(rzs.data.body.data)
          console.log(date)

          date.list.forEach(item => {
            if (item.allot_type == 1) {
              this.$set(item, 'zhuangtai', '新增')
            } else if (item.allot_type == 2) {
              this.$set(item, 'zhuangtai', '系统分配')
            } else if (item.allot_type == 3) {
              this.$set(item, 'zhuangtai', '指派')
            }
            let chengjiao_jine = ''
            if (item.amt_trans != undefined) {
              const jine_list = item.amt_trans.split(',')
              const jine_zhan = []
              jine_list.forEach(it => {
                // jine_zhan.push(this.$qianwei(Number(it).toFixed(2)))
                jine_zhan.push(Number(it).toFixed(2))
              })
              chengjiao_jine = jine_zhan.join(',')
            }
            this.$set(item, 'chengjiao_jine', chengjiao_jine)
          })
          // amt_trans
          this.dangqian_zongshu = date.total
          this.tableData = date.list
        } else if (rzs.data.code == 500) {} else if (rzs.data.code == 10300) {
          this.$message({
            message: '企业还没有设置分配规则',
            type: 'warning'
          })
        }
      })
    },
    // 指派接口
    zhipai_jiekou () {
      // this.tanchuang_neirong={
      //     allot_user_name:'asdsa',
      //     list:[
      //         {
      //             cust_name:'1',
      //             zhi:'0'
      //         },
      //         {
      //             cust_name:'2',
      //             zhi:'0'
      //         },
      //         {
      //             cust_name:'3',
      //             zhi:'0'
      //         },
      //         {
      //             cust_name:'4',
      //             zhi:'0'
      //         },
      //         {
      //             cust_name:'51',
      //             zhi:'0'
      //         },
      //         {
      //             cust_name:'6',
      //             zhi:'0'
      //         },
      //     ]
      // }
      query_customer_allot_log_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          //     禁止动  这块没问题  我打保票
          if (date.length != 0) {
            if (Number(this.xunhuan_xiabiao) > Number(date.length)) {
              this.tishi_fenpei_kehu = false
            } else {
              date[this.xunhuan_xiabiao].list.forEach(item => {
                this.$set(item, 'zhi', '0')
              })
              this.tanchuang_neirong = date[this.xunhuan_xiabiao]
              this.tishi_fenpei_kehu = true
            }
          } else {
            this.tishi_fenpei_kehu = false
          }
        } else if (res.data.code == 500) {} else if (res.data.code == 10300) {
          this.$message({
            message: '企业还没有设置分配规则',
            type: 'warning'
          })
        }
      })
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    // zhipai_jieshou
    dianji_fanhui () {
      this.$router.push('/cusman')
    },
    dianji_tiaoshu (val) {
      console.log(`每页 ${val} 条`)
      this.tiaoshu = val
      this.dangqian_yeshu = 1
    },
    // 已分配里点击事件
    dianji_fenpei (i, index) {
      console.log(i)
      if (i == '显示设置') {
        this.biao1_tit.forEach(item => {
          this.zhanshi_list.forEach(ite => {
            if (item.con == ite.con) {
              this.xuanze_danxuan(ite)
            }
          })
        })
        this.xianshi_shezhi = true
      } else if (i == '新增') {
        // query_flow_form_have_set({data:{
        //     ent_id:this.$ent_id(),
        //     flow_id:'7TvJHPyovM'
        // }}).then(ras=>{
        //     console.log(ras)
        //     if(ras.data.code==10245){
        query_flow_info_all({
          data: {
            ent_id: this.$ent_id(),
            flow_id: '7TvJHPyovM'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = res.data.body.form_str
            console.log(date)
            const data = []
            for (let a = 0; a < date.length; a++) {
              if (date[a].defaultImportant != undefined) {
                date[a].defaultImportant = date[a].defaultImportant != 'false'
              }
              if (date[a].defaultPrint != undefined) {
                date[a].defaultPrint = date[a].defaultPrint != 'false'
              }
              if (date[a].defaultOptions != undefined) {
                date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
              }
              if (date[a].gongshi != undefined) {
                date[a].gongshi = JSON.parse(date[a].gongshi)
              }
              if (date[a].supportSetting != undefined) {
                date[a].supportSetting = JSON.parse(date[a].supportSetting)
              }
              if (date[a].components != undefined) {
                date[a].components = JSON.parse(date[a].components)
                for (let b = 0; b < date[a].components.length; b++) {
                  if (date[a].components[b].defaultImportant != undefined) {
                    if (typeof (date[a].components[b].defaultImportant) === 'string') {
                      date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                    }
                  }
                  if (date[a].components[b].defaultPrint != undefined) {
                    if (typeof (date[a].components[b].defaultPrint) === 'string') {
                      date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                    }
                  }
                  // typeof(str)=='string'
                  if (date[a].components[b].defaultOptions != undefined) {
                    if (typeof (date[a].components[b].defaultOptions) === 'string') {
                      date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                    }
                  }
                  if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                    if (typeof (date[a].components[b].gongshi) === 'string') {
                      console.log(date[a].components[b].gongshi)
                      date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                    }
                  }
                  if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                    if (typeof (date[a].components[b].supportSetting) === 'string') {
                      console.log(date[a].components[b].supportSetting)
                      date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                    }
                  }
                }
              }
              data.push(date[a])
            }
            const obj = {
              flow_id: '7TvJHPyovM',
	                                flow_name: '客户录入单'
            }
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '已分配', '暂时')
            this.$router.push('/usetable')
          } else if (res.data.code == 500) {}
        })
        //     }else if(ras.data.code==10246){
        //         this.shifou=true
        //         this.danzi_name='客户录入单'
        //     }
        // })
      } else if (i == '看我') {
        this.kan_shei = index
        console.log(this.sousuo_text)
        // fenpei_sj_zhi
        // let liexing=this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null
        console.log(this.fenpei_time)
        // let date=this.fenpei_time
        // let start_time=null
        // let end_time=null
        // if(date!=null&&date!=undefined&&date.length!=0){
        // start_time=date[0].getFullYear() + "-" + this.$func.pa(date[0].getMonth() + 1) + "-"+this.$func.pa(date[0].getDate())
        // end_time=date[1].getFullYear() + "-" + this.$func.pa(date[1].getMonth() + 1) + "-"+this.$func.pa(date[1].getDate())
        // }
        const obj = {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
          // time_type:this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null,
          // date_start:start_time,
          // date_end:end_time,
          look_type: this.kan_shei == '-1' ? '2' : (this.biaotou1_list[this.kan_shei] == '看员工' ? '1' : (this.biaotou1_list[this.kan_shei] == '看我' ? '2' : '3')),
          user_id: this.$jichuxinxi().user_id,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + ''
        }
        this.sousuo_jiekou(obj)
        this.$forceUpdate()
      } else if (i == '看员工') {
        this.kan_shei = index
        console.log(this.sousuo_text)
        // fenpei_sj_zhi
        // let liexing=this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null
        // console.log(this.fenpei_time)
        // let date=this.fenpei_time
        // let start_time=null
        // let end_time=null
        // if(date!=null&&date!=undefined&&date.length!=0){
        //     start_time=date[0].getFullYear() + "-" + this.$func.pa(date[0].getMonth() + 1) + "-"+this.$func.pa(date[0].getDate())
        //     end_time=date[1].getFullYear() + "-" + this.$func.pa(date[1].getMonth() + 1) + "-"+this.$func.pa(date[1].getDate())
        // }
        const obj = {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
          // time_type:this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null,
          // date_start:start_time,
          // date_end:end_time,
          look_type: this.kan_shei == '-1' ? '2' : (this.biaotou1_list[this.kan_shei] == '看员工' ? '1' : (this.biaotou1_list[this.kan_shei] == '看我' ? '2' : '3')),
          user_id: this.$jichuxinxi().user_id,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + ''
        }
        this.sousuo_jiekou(obj)
        this.$forceUpdate()
      } else if (i == '退回') {
        // back_customer
        console.log(this.xuan_zhong)

        if (this.xuan_zhong.length == 1) {
          const cuowu = []
          this.xuan_zhong.forEach(item => {
            if (item.staff_id != this.$jichuxinxi().user_id) {
              cuowu.push('111')
            }
          })
          if (cuowu.length == 0) {
            // s1.split('')
            const id_list = this.xuan_zhong[0].dept_id.split(',')
            const name_list = this.xuan_zhong[0].dept_name.split(',')
            const tuihui_bumen_list = []
            for (let tuihui_num = 0; tuihui_num < name_list.length; tuihui_num++) {
              tuihui_bumen_list.push({
                dept_id: id_list[tuihui_num],
                dept_name: name_list[tuihui_num]
              })
            }
            this.tuihui_dept_list = tuihui_bumen_list
            this.tuihui_bumen = true
          } else {
            this.tuihui_bumen = false
            this.$message({
              message: '请选择属于自己的客户进行退回',
              type: 'warning'
            })
          }
        } else {
          this.$message({
            message: '请选择单个客户进行退回',
            type: 'warning'
          })
        }
      } else if (i == '系统分配') {
        query_staff_dept_list({
          data: {
            ent_id: this.$ent_id(),
            staff_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          this.xitong_fenpei_zhanshi = true
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.dept_list = date
            this.dept_id = ''
          } else if (res.data.code == 500) {} else if (res.data.code == 10250) {
            this.$message({
              message: '公海池客户目前不够分配',
              type: 'warning'
            })
          } else if (res.data.code == 10251) {
            this.$message({
              message: '公海池客户已超额分配',
              type: 'warning'
            })
          } else if (res.data.code == 10300) {
            this.$message({
              message: '企业还没有设置分配规则',
              type: 'warning'
            })
          }
        })
      } else if (i == '编辑') {
        if (this.xuan_zhong.length == 1) {
          console.log(this.xuan_zhong[0])
          if ((this.xuan_zhong[0].staff_id == this.$jichuxinxi().user_id) || this.shifou_have_daixie) {
            query_customer_detail({
              data: {
                ent_id: this.$ent_id(),
                cust_id: this.xuan_zhong[0].cust_id,
                file_no: this.xuan_zhong[0].file_no,
                dept_id: this.xuan_zhong[0].dept_id
              }
            }).then(rds => {
              console.log(rds)
              if (rds.data.code == 200) {
                const xiangqing = JSON.parse(rds.data.body.data)
                console.log(xiangqing)
                if (xiangqing.flow_status == undefined || xiangqing.flow_status == 4 || xiangqing.flow_status == 2 || xiangqing.flow_status == 7) {
                  // query_flow_form_have_set({data:{
                  //     ent_id:this.$ent_id(),
                  //     flow_id:'7TvJHPyovM'
                  // }}).then(ras=>{
                  //     console.log(ras)
                  //     if(ras.data.code==10245){
                  query_flow_info_all({
                    data: {
                      ent_id: this.$ent_id(),
                      flow_id: '7TvJHPyovM'
                    }
                  }).then(res => {
                    console.log(res)
                    if (res.data.code == 200) {
                      const date = res.data.body.form_str
                      console.log(date)
                      const data = []
                      for (let a = 0; a < date.length; a++) {
                        if (date[a].defaultImportant != undefined) {
                          date[a].defaultImportant = date[a].defaultImportant != 'false'
                        }
                        if (date[a].defaultPrint != undefined) {
                          date[a].defaultPrint = date[a].defaultPrint != 'false'
                        }
                        if (date[a].defaultOptions != undefined) {
                          date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
                        }
                        if (date[a].gongshi != undefined) {
                          date[a].gongshi = JSON.parse(date[a].gongshi)
                        }
                        if (date[a].supportSetting != undefined) {
                          date[a].supportSetting = JSON.parse(date[a].supportSetting)
                        }
                        if (date[a].components != undefined) {
                          date[a].components = JSON.parse(date[a].components)
                          for (let b = 0; b < date[a].components.length; b++) {
                            if (date[a].components[b].defaultImportant != undefined) {
                              if (typeof (date[a].components[b].defaultImportant) === 'string') {
                                date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                              }
                            }
                            if (date[a].components[b].defaultPrint != undefined) {
                              if (typeof (date[a].components[b].defaultPrint) === 'string') {
                                date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                              }
                            }
                            // typeof(str)=='string'
                            if (date[a].components[b].defaultOptions != undefined) {
                              if (typeof (date[a].components[b].defaultOptions) === 'string') {
                                date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                              }
                            }
                            if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                              if (typeof (date[a].components[b].gongshi) === 'string') {
                                console.log(date[a].components[b].gongshi)
                                date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                              }
                            }
                            if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                              if (typeof (date[a].components[b].supportSetting) === 'string') {
                                console.log(date[a].components[b].supportSetting)
                                date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                              }
                            }
                          }
                        }
                        data.push(date[a])
                      }
                      const obj = {
                        flow_id: '7TvJHPyovM',
                        flow_name: '客户录入单'
                      }
                      data.forEach(item => {
                        if (item.componentName == 'textfield' && item.defaultLable == '客户' && item.idx == 2) {
                          console.log(xiangqing.cust_name)
                          this.$set(item, 'value', xiangqing.cust_name)
                          this.$set(item, 'value_id', xiangqing.cust_id)
                        }
                        if (item.componentName == 'department' && item.defaultLable == '部门' && item.idx == 1) {
                          this.$set(item, 'dept_id', this.xuan_zhong[0].dept_id)
                          this.$set(item, 'value', this.xuan_zhong[0].dept_name)
                        }
                        if (item.componentName == 'ddselectfield' && item.defaultLable == '地区' && item.idx == 16) {
                          this.$set(item, 'value', xiangqing.province)
                          this.$set(item, 'value_id', xiangqing.city)
                        }
                        if (item.componentName == 'ddselectfield' && item.defaultLable == '行业' && item.idx == 17) {
                          console.log(item)
                          item.defaultOptions.forEach((ite, ind) => {
                            if (ite.text == xiangqing.industry) {
                              this.$set(item, 'value', ind)
                              this.$set(item, 'teshu', false)
                            }
                          })
                          if (item.value == undefined) {
                            this.$set(item, 'value', '0')
                            this.$set(item, 'teshu', false)
                          }
                        }
                        if (item.componentName == 'ddselectfield' && item.defaultLable == '客户来源' && item.idx == 18) {
                          console.log(item)
                          item.defaultOptions.forEach((ite, ind) => {
                            if (ite.text == xiangqing.source) {
                              this.$set(item, 'value', ind)
                              this.$set(item, 'teshu', false)
                            }
                          })
                          if (item.value == undefined) {
                            this.$set(item, 'value', '0')
                            this.$set(item, 'teshu', false)
                          }
                        }
                        if (item.componentName == 'ddselectfield' && item.defaultLable == '客户等级' && item.idx == 19) {
                          console.log(item)
                          item.defaultOptions.forEach((ite, ind) => {
                            if (ite.text == xiangqing.level) {
                              this.$set(item, 'value', ind)
                              this.$set(item, 'teshu', false)
                            }
                          })
                          if (item.value == undefined) {
                            this.$set(item, 'value', '0')
                            this.$set(item, 'teshu', false)
                          }
                        }
                        if (item.componentName == 'ddmultiselectfield' && item.defaultLable == '客户标签' && item.idx == 20) {
                          this.$set(item, 'teshu', false)
                          this.$set(item, 'value', xiangqing.tag_name)
                          this.$set(item, 'value_id', xiangqing.tag)
                        }
                        if (item.componentName == 'textfield' && item.defaultLable == '经营地址' && item.idx == 21) {
                          this.$set(item, 'value', xiangqing.biz_addr != undefined ? xiangqing.biz_addr : '')
                          this.$set(item, 'value_id', 0)
                        }
                        if (item.componentName == 'textfield' && item.defaultLable == '母公司名称' && item.idx == 22) {
                          this.$set(item, 'value', xiangqing.rel_p_com != undefined ? xiangqing.rel_p_com : '')
                          this.$set(item, 'value_id', 0)
                        }
                      })
                      console.log(data)
                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '已分配_编辑', '暂时')
                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_4', xiangqing, '暂时')
                      this.$router.push('/usetable')
                    } else if (res.data.code == 500) {}
                  })
                  //     }else if(ras.data.code==10246){
                  //         this.shifou=true
                  //         this.danzi_name='客户录入单'
                  //     }
                  // })
                } else {
                  this.$message({
                    message: '当前客户尚未审批完成',
                    type: 'warning'
                  })
                }
              } else if (rds.data.code == 500) {}
            })
          } else {
            this.$message({
              message: '您不能编辑不属于自己的客户',
              type: 'warning'
            })
          }
        } else {
          this.$message({
            message: '请选择单个客户进行编辑',
            type: 'warning'
          })
        }
      } else if (i == '看全部') {
        this.kan_shei = index
        console.log(this.sousuo_text)
        // fenpei_sj_zhi
        // let liexing=this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null
        // console.log(this.fenpei_time)
        // let date=this.fenpei_time
        // let start_time=null
        // let end_time=null
        // if(date!=null&&date!=undefined&&date.length!=0){
        //     start_time=date[0].getFullYear() + "-" + this.$func.pa(date[0].getMonth() + 1) + "-"+this.$func.pa(date[0].getDate())
        //     end_time=date[1].getFullYear() + "-" + this.$func.pa(date[1].getMonth() + 1) + "-"+this.$func.pa(date[1].getDate())
        // }
        const obj = {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
          // time_type:this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null,
          // date_start:start_time,
          // date_end:end_time,
          look_type: this.kan_shei == '-1' ? '2' : (this.biaotou1_list[this.kan_shei] == '看员工' ? '1' : (this.biaotou1_list[this.kan_shei] == '看我' ? '2' : '3')),
          user_id: this.$jichuxinxi().user_id,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + ''
        }
        this.sousuo_jiekou(obj)
        this.$forceUpdate()
      }
      // else if(i=='导入/导出'){
      //     this.$router.push('/importexport')
      // }
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    xuanzhong (val) {
      this.xuan_zhong = val
      console.log(val)
    },
    // 点击选择全部
    dianji_xuanze_quanbu () {
      this.xianshi_quanbu = !this.xianshi_quanbu
      if (this.xianshi_quanbu) {
        this.zhanshi_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list = []
        for (let i = 0; i < this.zhanshi_list.length; i++) {
          this.xianshi_xiang_list.push(this.zhanshi_list[i])
        }
      } else {
        this.zhanshi_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list = []
      }
    },
    xuanze_danxuan (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
          if (this.xianshi_xiang_list[a].con == i.con) {
            this.xianshi_xiang_list.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi (i, index) {
      this.zhanshi_list.map((item, idx) => {
        if (item.con == i.con) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list.splice(index, 1)
    },
    dianji_lie_queding () {
      this.biao1_tit = this.xianshi_xiang_list
      this.xianshi_xiang_list = []
      this.xianshi_shezhi = false
    },
    sousuo () {
      console.log(this.sousuo_text)
      // fenpei_sj_zhi
      // let liexing=this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null
      // console.log(this.fenpei_time)
      // let date=this.fenpei_time
      // let start_time=null
      // let end_time=null
      // if(date!=null&&date!=undefined&&date.length!=0){
      //     start_time=date[0].getFullYear() + "-" + this.$func.pa(date[0].getMonth() + 1) + "-"+this.$func.pa(date[0].getDate())
      //     end_time=date[1].getFullYear() + "-" + this.$func.pa(date[1].getMonth() + 1) + "-"+this.$func.pa(date[1].getDate())
      // }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null,
        // date_start:start_time,
        // date_end:end_time,
        look_type: this.kan_shei == '-1' ? '2' : (this.biaotou1_list[this.kan_shei] == '看员工' ? '1' : (this.biaotou1_list[this.kan_shei] == '看我' ? '2' : '3')),
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: '1'
      }
      this.dangqian_yeshu = 1
      this.sousuo_jiekou(obj)
    },
    rqi_xuanze () {
      console.log(this.fenpei_sj_zhi)
      console.log(this.fenpei_time)
      // this.fenpei_sj_zhi=this.fenpei_sj_zhi.length==0?'2':this.fenpei_sj_zhi
      // let date=this.fenpei_time
      // let start_time=null
      // let end_time=null
      // if(date!=null&&date!=undefined&&date.length!=0){
      //     start_time=date[0].getFullYear() + "-" + this.$func.pa(date[0].getMonth() + 1) + "-"+this.$func.pa(date[0].getDate())
      //     end_time=date[1].getFullYear() + "-" + this.$func.pa(date[1].getMonth() + 1) + "-"+this.$func.pa(date[1].getDate())
      // }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null,
        // date_start:start_time,
        // date_end:end_time,
        look_type: this.kan_shei == '-1' ? '2' : (this.biaotou1_list[this.kan_shei] == '看员工' ? '1' : (this.biaotou1_list[this.kan_shei] == '看我' ? '2' : '3')),
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + ''
      }
      this.sousuo_jiekou(obj)
    },
    sousuo_jiekou (obj) {
      query_customer_list_alloted({ data: obj }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.list.forEach(item => {
            if (item.allot_type == 1) {
              this.$set(item, 'zhuangtai', '新增')
            } else if (item.allot_type == 2) {
              this.$set(item, 'zhuangtai', '系统分配')
            } else if (item.allot_type == 3) {
              this.$set(item, 'zhuangtai', '指派')
            }
            let chengjiao_jine = ''
            if (item.amt_trans != undefined) {
              const jine_list = item.amt_trans.split(',')
              const jine_zhan = []
              jine_list.forEach(it => {
                jine_zhan.push(Number(it).toFixed(2))
              })
              chengjiao_jine = jine_zhan.join(',')
            }
            this.$set(item, 'chengjiao_jine', chengjiao_jine)
          })
          // amt_trans
          this.dangqian_zongshu = date.total
          this.tableData = date.list
        } else if (res.data.code == 500) {}
      })
    },
    shijian_xuanze () {
      const date = this.fenpei_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
        const obj = {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
          // time_type:this.fenpei_sj_zhi.length!=0?this.fenpei_sj_zhi:null,
          // date_start:start_time,
          // date_end:end_time,
          look_type: this.kan_shei == '-1' ? '2' : (this.biaotou1_list[this.kan_shei] == '看员工' ? '1' : (this.biaotou1_list[this.kan_shei] == '看我' ? '2' : '3')),
          user_id: this.$jichuxinxi().user_id,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + ''
        }
        this.sousuo_jiekou(obj)
      }
    },
    // 系统分配选择部门点击确定
    xitong_fenpei () {
      if (this.dept_id.length != 0) {
        // query_user_info({data:{
        //     ent_id:this.$ent_id(),
        //     user_id:this.$jichuxinxi().user_id
        // }}).then(ras=>{
        //     console.log(ras)
        //     if(ras.data.code==200){
        //         let date=JSON.parse(ras.data.body.data)
        system_allot_customer({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            // operate_dept_id:date.dept_ids!=undefined&&date.dept_ids!=null&&date.dept_ids.length!=0?date.dept_ids:null,
            dept_id: this.dept_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '调整成功',
              type: 'success'
            })
            this.xitong_fenpei_zhanshi = false
            this.jichu()
          } else if (res.data.code == 500) {} else if (res.data.code == 10250) {
            this.$message({
              message: '公海池客户目前不够分配',
              type: 'warning'
            })
          } else if (res.data.code == 10251) {
            this.$message({
              message: '公海池客户已超额分配',
              type: 'warning'
            })
          } else if (res.data.code == 10300) {
            this.$message({
              message: '企业还没有设置分配规则',
              type: 'warning'
            })
          }
        })
        //     }else if(ras.data.code==500){}
        // })
      } else {
        this.$message({
          message: '请选择部门',
          type: 'warning'
        })
      }
    },
    // 指派接受
    zhipai_dongzuo (i) {
      if (i == 1) {
        const list = []
        this.tanchuang_neirong.list.forEach(item => {
          list.push({
            cust_id: item.cust_id,
            accept_status: item.zhi == 0 ? '1' : '2',
            dept_id: item.dept_id
          })
        })
        query_user_info({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(ras => {
          console.log(ras)
          if (ras.data.code == 200) {
            const date = ras.data.body
            update_customer_allot_log({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                // cust_id:this.tanchuang_neirong.cust_id,
                // dept_id:this.tanchuang_neirong.list[0].dept_id,
                operate_dept_id: date.dept_ids != undefined && date.dept_ids != null && date.dept_ids.length != 0 ? date.dept_ids : null,
                allot_user_id: this.tanchuang_neirong.allot_user_id,
                // accept_status:i+'',
                list: list
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 200) {
                this.$message({
                  message: '操作成功',
                  type: 'success'
                })
                this.tishi_fenpei_kehu = false
                this.jichu()
              } else if (res.data.code == 500) {}
            })
          } else if (ras.data.code == 500) {}
        })
      } else if (i == 2) {
        this.xunhuan_xiabiao = Number(this.xunhuan_xiabiao) + 1
        this.tishi_fenpei_kehu = false
        this.jichu()
      }
    },
    // 表格行点
    hangdian (val) {
      console.log(val)
      query_customer_detail({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          file_no: val.file_no,
          dept_id: val.dept_id
        }
      }).then(rds => {
        console.log(rds)
        if (rds.data.code == 200) {
          const xiangqing = JSON.parse(rds.data.body.data)
          console.log(xiangqing)

          this.xiangxi_xinxi = []
          this.xiangxi_xinxi.push(xiangqing)
          this.xiangxi_xinxi.forEach(item => {
            this.$set(item, 'zidingyi', item.extra != undefined ? JSON.parse(item.extra) : [])
            this.$set(item, 'staff_name', val.staff_name != undefined ? val.staff_name : '')
            this.$set(item, 'dept_name', val.dept_name != undefined ? val.dept_name : '')
          })
          // 附件下载接口
          customer_annex_download({
            data: {
              ent_id: this.$ent_id(),
              cust_id: val.cust_id,
              // dept_id:val.dept_id,
              file_no: xiangqing.file_no,
              staff_id: val.staff_id != undefined ? val.staff_id : null
            }
          }).then(fujian => {
            console.log(fujian)
            if (fujian.data.code == 200) {
              let date = JSON.parse(fujian.data.body.data)
              console.log(date)

              if (date.length != 0) {
                date.forEach(item => {
                  if (item.con_annex != undefined) {
                    item.con_annex.forEach(ite => {
                      this.$set(ite, 'zhi', false)
                    })
                  }
                  if (item.inv_annex != undefined) {
                    item.inv_annex.forEach(ite => {
                      this.$set(ite, 'zhi', false)
                    })
                  }
                  if (item.rec_annex != undefined) {
                    item.rec_annex.forEach(ite => {
                      this.$set(ite, 'zhi', false)
                    })
                  }
                  if (item.deliver_annex != undefined) {
                    item.deliver_annex.forEach(ite => {
                      this.$set(ite, 'zhi', false)
                    })
                  }
                })
              } else {
                date = []
              }
              for (let i = 0; i < date.length; i++) {
                if (date[i].file_no == undefined) {
                  date.splice(i, 1)
                  i = i - 1
                }
              }
              setTimeout(() => {
                this.fujian_list = date
              }, 0)
            } else if (fujian.data.code == 500) {}
          })
        } else if (rds.data.code == 500) {}
      })

      query_customer_mng_log_list({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.zhanshi_lishi = date != undefined && date != null && date.length != 0 ? date : []
          // let list=[]
          // date.forEach(ite=>{
          //     if(ite.type!=4&&ite.type!=5){
          //         list.push(ite)
          //     }
          // })
          // query_cust_allot_log_list({data:{
          //     ent_id:this.$ent_id(),
          //     // user_id:this.$jichuxinxi().user_id,
          //     cust_id:val.cust_id
          // }}).then(ras=>{
          //     console.log(ras)
          //     if(ras.data.code==200){
          //         let data=JSON.parse(ras.data.body.data)
          //         console.log(data)
          //         data.forEach(item=>{
          //             if(item.accept_status==0){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',7)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }else if(item.accept_status==1){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',4)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }else if(item.accept_status==2){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',5)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }
          //         })
          //         list=this.$func.ReverseRankingDate(list,'operate_time')
          //         console.log(list)
          //         this.zhanshi_lishi=list
          //     }else if(res.data.code==500){}
          // })
        } else if (res.data.code == 500) {}
      })
      query_customer_else_info({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          type: '1',
          dept_id: val.dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.allot_type == 1) {
            this.right_zhanshi.zhuangtai = '新增'
          } else if (date.allot_type == 2) {
            this.right_zhanshi.zhuangtai = '系统分配'
          } else if (date.allot_type == 3) {
            this.right_zhanshi.zhuangtai = '指派'
          }
          this.right_zhanshi.chengjiao_sj = date.date_trans.length != 0 ? date.date_trans : '—'
          this.right_zhanshi.chengjiao_jine = Number(date.amt_trans).toFixed(2)
          this.right_zhanshi.tuihui_cishu = date.back_num
        } else if (res.data.code == 500) {}
      })
      this.youce_xiangqing = true
    },
    fujian_xiazai (i) {
      this.$func.fujian_xiazai2(i)
    },
    dianji_guidang_hao (index) {
      this.zhanshi_guidang_hao = index
      const item = this.fujian_list[index]
      if (item != undefined) {
        if (item.con_annex != undefined) {
          item.con_annex.forEach(ite => {
            this.$set(ite, 'zhi', false)
          })
        }
        if (item.inv_annex != undefined) {
          item.inv_annex.forEach(ite => {
            this.$set(ite, 'zhi', false)
          })
        }
        if (item.rec_annex != undefined) {
          item.rec_annex.forEach(ite => {
            this.$set(ite, 'zhi', false)
          })
        }
        if (item.deliver_annex != undefined) {
          item.deliver_annex.forEach(ite => {
            this.$set(ite, 'zhi', false)
          })
        }
      }
    },
    dianji_quanxuan_fujian () {
      this.fujian_quanxuan = !this.fujian_quanxuan
      if (this.fujian_quanxuan) {
        const item = this.fujian_list[this.zhanshi_guidang_hao]
        if (item != undefined) {
          if (item.con_annex != undefined) {
            item.con_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.inv_annex != undefined) {
            item.inv_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.rec_annex != undefined) {
            item.rec_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.deliver_annex != undefined) {
            item.deliver_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
        }
      } else {
        const item = this.fujian_list[this.zhanshi_guidang_hao]
        if (item != undefined) {
          if (item.con_annex != undefined) {
            item.con_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.inv_annex != undefined) {
            item.inv_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.rec_annex != undefined) {
            item.rec_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.deliver_annex != undefined) {
            item.deliver_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
        }
      }
    },
    dianji_piliang_xiazi () {
      const list = []
      const item = this.fujian_list[this.zhanshi_guidang_hao]
      if (item != undefined) {
        if (item.con_annex != undefined) {
          item.con_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.inv_annex != undefined) {
          item.inv_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.rec_annex != undefined) {
          item.rec_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.deliver_annex != undefined) {
          item.deliver_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (list.length != 0) {
          list.forEach(it => {
            this.$func.fujian_xiazai2(it)
          })
        } else {
          this.$message({
            message: '请选择附件进行下载',
            type: 'warning'
          })
        }
      }
    },
    panduan_fujian_quanxuan () {
      const list = []
      const item = this.fujian_list[this.zhanshi_guidang_hao]
      console.log(item)
      if (item != undefined) {
        if (item.con_annex != undefined) {
          const quanbu = []
          item.con_annex.forEach(ite => {
            console.log(ite)
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.con_annex.length) {
            list.push('1111')
          }
          console.log(quanbu)
        } else {
          list.push('1111')
        }
        if (item.inv_annex != undefined) {
          const quanbu = []
          item.inv_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.inv_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        if (item.rec_annex != undefined) {
          const quanbu = []
          item.rec_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.rec_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        if (item.deliver_annex != undefined) {
          const quanbu = []
          item.deliver_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.deliver_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        setTimeout(() => {
          console.log(list)
          if (list.length == 4 && (item.deliver_annex != undefined || item.con_annex != undefined || item.inv_annex != undefined || item.rec_annex != undefined)) {
            this.fujian_quanxuan = true
          } else {
            this.fujian_quanxuan = false
          }
        }, 0)
      }
    },
    // 退回点击确定
    tuihui_queding () {
      back_customer({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          dept_id: this.tuihui_dept_id,
          list: this.xuan_zhong.map(item => {
            return {
              cust_id: item.cust_id,
              allot_type: item.allot_type
            }
          })
        }
      }).then(res => {
        console.log(res)
        this.tuihui_bumen = false
        if (res.data.code == 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.jichu()
        } else if (res.data.code == 500) {} else if (res.data.code == 10300) {
          this.$message({
            message: '企业还没有设置分配规则',
            type: 'warning'
          })
        }
      })
    },
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    jieduan_hanzi (i) {
      if (i == '1') {
        return '初步接触'
      } else if (i == '2') {
        return '需求确认'
      } else if (i == '3') {
        return '报价及方案展示'
      } else if (i == '4') {
        return '商务谈判'
      } else if (i == '5') {
        return '赢单'
      } else if (i == '6') {
        return '输单'
      }
    },
    // 点击表单详情
    biaodan_xiangqing (i) {
      this.$refs.biaodan_xiangqing_zhanshi[0].jichu(i)
    },
    // 点击眼睛
    dakai_danju (i) {
      this.$refs.eye.dianji_dakai(i[0])
    },
    tupian_dianji (img) {
      this.$refs.bigphote.jichu(img)
    },
    // 金额转化
    jine_zhuanhua (num) {
      const a = Number(num)
      if (a >= 100000000) {
        return this.$qianwei((a / 100000000).toFixed(2))
      } else if (a >= 10000 && a < 100000000) {
        return this.$qianwei((a / 10000).toFixed(2))
      } else if (a < 10000) {
        return this.$qianwei(a.toFixed(2))
      }
    },
    // 金额文字转化
    wenzi_jine_zhuanhua (num) {
      const a = Number(num)
      if (a >= 100000000) {
        return '亿'
      } else if (a >= 10000 && a < 100000000) {
        return '万元'
      } else if (a < 10000) {
        return '元'
      }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss';
 .yanjing_boxS table td{
   width: 114px;
 }
</style>
